<template>
    <v-dialog :value="value" persistent max-width="500" @click:outside="closeDialog()">
      <v-card color="fill">
        <v-container class="adGroupModal">
          <v-card-title class="my-0 py-0 pt-0 heading-6">
            <v-row justify="space-between" class="my-2">
              <v-col class="ma-0 pa-0 pb-4" align="start">
                <div class="text-no-wrap">
                  Contract Ad Groups
                </div>
              </v-col>
              <v-col class="pa-0 ma-0 pr-4" align="end">
                <v-btn icon @click="closeDialog()">
                  <v-icon dense>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <template>
              <v-data-table
                :headers="headers"
                :items.sync="mappedGroups"
                fixed-header
                height="365"
                :items-per-page="-1"
                dense>
                <template v-slot:[`item.name`] ='{ item }'>
                  <td class="pl-2">{{ item.name }}</td>
                </template>
              </v-data-table>
            </template>
            </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
  </template>
  <script>

  export default {
    name: 'AdGroupsModal',
    props: [
      'value',
      'selectedAdGroups'
    ],
    data () {
      return {
        headers: [
        { text: 'Name', value: 'name'},
        ]
      }
    },
    computed: {
        mappedGroups() {
           return this.selectedAdGroups.map(a => (this.$store.getters.adGroups.find(b => b.id === a)))
        }
    },
    methods: {
      closeDialog() {
        this.$emit('closeDialog')
      }
    }
  }
  </script>
  <style>
  .adGroupModal .theme--light.v-data-table .v-data-table-header th {
      color: #fff !important;
      background-color: #385F73 !important;
    }
  </style>