var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { value: _vm.value, persistent: "", "max-width": "500" },
      on: {
        "click:outside": function($event) {
          return _vm.closeDialog()
        }
      }
    },
    [
      _c(
        "v-card",
        { attrs: { color: "fill" } },
        [
          _c(
            "v-container",
            { staticClass: "adGroupModal" },
            [
              _c(
                "v-card-title",
                { staticClass: "my-0 py-0 pt-0 heading-6" },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "my-2",
                      attrs: { justify: "space-between" }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ma-0 pa-0 pb-4",
                          attrs: { align: "start" }
                        },
                        [
                          _c("div", { staticClass: "text-no-wrap" }, [
                            _vm._v(" Contract Ad Groups ")
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 ma-0 pr-4",
                          attrs: { align: "end" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDialog()
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { dense: "" } }, [
                                _vm._v("mdi-close")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  [
                    _c("v-data-table", {
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.mappedGroups,
                        "fixed-header": "",
                        height: "365",
                        "items-per-page": -1,
                        dense: ""
                      },
                      on: {
                        "update:items": function($event) {
                          _vm.mappedGroups = $event
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "item.name",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("td", { staticClass: "pl-2" }, [
                                  _vm._v(_vm._s(item.name))
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }